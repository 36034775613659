import { BottomSheet } from "@stackflow/plugin-basic-ui";
import React from "react";

import type { ActivityLayoutComponentType } from "../../../../stackflow/hocs/withActivityRenderer";
import { type ActivityParams } from "./ActivitySocialCapitalShareBottomSheet";

const ActivitySocialCapitalShareBottomSheetLayout: ActivityLayoutComponentType<
  ActivityParams
> = ({ children }) => {
  return <BottomSheet>{children}</BottomSheet>;
};

export default ActivitySocialCapitalShareBottomSheetLayout;
