import React from "react";

import { Screen } from "../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../stackflow/hocs/withActivityRenderer";
import { type ScreenParams } from "./ActivitySocialCapitalSeasonCounting";

const ActivitySocialCapitalReviewLandingLayout: ActivityLayoutComponentType<
  ScreenParams
> = ({ children }) => {
  return (
    <Screen
      appBar={{
        border: false,
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivitySocialCapitalReviewLandingLayout;
