import { logEvent } from "@daangn/local-business-analytics";
import { karrotAnalyticsPlugin } from "@daangn/stackflow-plugin-karrot-analytics";
import { vars } from "@seed-design/design-token";
import { basicUIPlugin, IconBack } from "@stackflow/plugin-basic-ui";
import { devtoolsPlugin } from "@stackflow/plugin-devtools";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { stackDepthChangePlugin } from "@stackflow/plugin-stack-depth-change";
import { stackflow } from "@stackflow/react";
import { lazy } from "react";

import ActivityNotFound from "../activities/404/ActivityNotFound";
import ActivityLocalProfileSuggestionLandingLayout from "../activities/local-profile-suggestions/[local_profile_suggestion_id]/landing/ActivityLocalProfileSuggestionLanding.layout";
import ActivityLocalProfileUpdateLayout from "../activities/local-profiles/[local_profile_id]/update/ActivityLocalProfileUpdate.layout";
import ActivityLocalProfileUpdateBackgroundImageLayout from "../activities/local-profiles/[local_profile_id]/update/background-image/ActivityLocalProfileUpdateBackgroundImage.layout";
import ActivityLocalProfileContributeLayout from "../activities/local-profiles/contribute/ActivityLocalProfileContribute.layout";
import ActivityLocalProfileContributeRecommendationsLayout from "../activities/local-profiles/contribute/recommendations/ActivityLocalProfileContributeRecommendations.layout";
import ActivityLayoutUserProfile from "../activities/profiles/[profile_user_id]/ActivityUserProfile.layout";
import ActivityLayoutUserProfileContributedLocalProfiles from "../activities/profiles/[profile_user_id]/contributed-local-profiles/ActivityUserProfileContributedLocalProfiles.layout";
import ActivityLayoutUserProfileReviews from "../activities/profiles/[profile_user_id]/local-profile-reviews/ActivityUserProfileReviews.layout";
import ActivityLayoutUserProfileSuggestions from "../activities/profiles/[profile_user_id]/local-profile-suggestions/ActivityUserProfileSuggestions.layout";
import ActivityUpdateReviewLayout from "../activities/reviews/[review_id]/edit/ActivityUpdateReview.layout";
import ActivityReviewLandingLayout from "../activities/reviews/[review_id]/landing/ActivityReviewLanding.layout";
import ActivityReviewPerformanceLandingLayout from "../activities/reviews/[review_id]/performance-landing/ActivityReviewPerformanceLanding.layout";
import ActivityCreateReviewLayout from "../activities/reviews/new/ActivityCreateReview.layout";
import ActivitySocialCapitalShareBottomSheetLayout from "../activities/social-capital/bottom-sheet/share/ActivitySocialCapitalShareBottomSheet.layout";
import ActivitySocialCapitalPointSystemLayout from "../activities/social-capital/point-system/ActivitySocialCapitalPointSystem.layout";
import ActivitySocialCapitalRedirectOnboardingOrDashboardLayout from "../activities/social-capital/redirect/onboarding-or-dashboard/ActivitySocialCapitalRedirectOnboardingOrDashboard.layout";
import ActivitySocialCapitalSearchLegalRegionLayout from "../activities/social-capital/regions/search-legal-region/ActivitySocialCapitalSearchLegalRegion.layout";
import ActivitySocialCapitalContributeReviewLandingLayout from "../activities/social-capital/reviews/[review_id]/contribute-landing/ActivitySocialCapitalContributeReviewLanding.layout";
import ActivitySocialCapitalReviewLandingLayout from "../activities/social-capital/reviews/[review_id]/landing/ActivitySocialCapitalReviewLanding.layout";
import ActivitySocialCapitalSeasonCountingLayout from "../activities/social-capital/seasons/[social_capital_season_key]/counting/ActivitySocialCapitalSeasonCounting.layout";
import ActivitySocialCapitalSeasonCountingFinishedLayout from "../activities/social-capital/seasons/[social_capital_season_key]/counting-finished/ActivitySocialCapitalSeasonCountingFinished.layout";
import ActivitySocialCapitalOnboardingLayout from "../activities/social-capital/seasons/[social_capital_season_key]/onboarding/ActivitySocialCapitalOnboarding.layout";
import ActivitySocialCapitalRankingLayout from "../activities/social-capital/seasons/[social_capital_season_key]/ranking/ActivitySocialCapitalRanking.layout";
import ActivitySocialCapitalRegionDashboardLayout from "../activities/social-capital/seasons/[social_capital_season_key]/regions/[region_id]/dashboard/ActivitySocialCapitalRegionDashboard.layout";
import ActivitySocialCapitalContributeSuggestionLandingLayout from "../activities/social-capital/suggestions/[suggestion_id]/contribute-landing/ActivitySocialCapitalContributeSuggestionLanding.layout";
import { STACKFLOW_TRANSITION_DURATION } from "../shared/constants/duration";
import {
  IS_PROD,
  LOCAL_BUSINESS_UGC_SERVICE_NAME,
  THEME,
} from "../shared/constants/environment";
import { bridge } from "../shared/lib/karrot-bridge/bridge";
import { localBusinessAnalyticsStackflowPlugin } from "../shared/lib/local-business-analytics/localBusinessAnalytics";
import { getQueryParam } from "../shared/utils/url/getQueryParam";
import { withActivityRenderer } from "./hocs/withActivityRenderer";
import { disableSocialCapitalPlugin } from "./plugins/disableSocialCapitalPlugin";
import { maintenanceModeSocialCapitalPlugin } from "./plugins/maintenanceModeSocialCapitalPlugin";

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */

const { actions, Stack, useFlow, useStepFlow, activities } = stackflow({
  transitionDuration: STACKFLOW_TRANSITION_DURATION,
  activities: {
    // 유저 프로필
    profile: withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/profiles/[profile_user_id]/ActivityUserProfile"
          ),
      ),
      layout: ActivityLayoutUserProfile,
    }),
    // 유저 프로필 온보딩 바텀시트
    "profile.onboarding_bottom_sheet": lazy(
      () =>
        import(
          "../activities/profiles/[profile_user_id]/onboarding-bottom-sheet/ActivityUserProfileOnboardingBottomSheet"
        ),
    ),
    // 유저가 작성한 후기 목록
    "profile.local_profile_reviews": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/profiles/[profile_user_id]/local-profile-reviews/ActivityUserProfileReviews"
          ),
      ),
      layout: ActivityLayoutUserProfileReviews,
    }),
    // 유저 제안 목록
    "profile.local_profile_suggestions": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/profiles/[profile_user_id]/local-profile-suggestions/ActivityUserProfileSuggestions"
          ),
      ),
      layout: ActivityLayoutUserProfileSuggestions,
    }),
    // 유저가 기여한 로컬프로필 목록
    "profile.contributed_local_profiles": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/profiles/[profile_user_id]/contributed-local-profiles/ActivityUserProfileContributedLocalProfiles"
          ),
      ),
      layout: ActivityLayoutUserProfileContributedLocalProfiles,
    }),
    // 후기 작성
    "review.create": withActivityRenderer({
      component: lazy(
        () => import("../activities/reviews/new/ActivityCreateReview"),
      ),
      layout: ActivityCreateReviewLayout,
    }),
    // 후기 상세
    review: withActivityRenderer({
      component: lazy(
        () => import("../activities/reviews/[review_id]/ActivityReview"),
      ),
      layout: ActivityReviewLandingLayout,
    }),
    // 후기 수정
    "review.update": withActivityRenderer({
      component: lazy(
        () =>
          import("../activities/reviews/[review_id]/edit/ActivityUpdateReview"),
      ),
      layout: ActivityUpdateReviewLayout,
    }),
    // 후기 수정/삭제 액션 시트
    "review.action_sheet": lazy(
      () =>
        import(
          "../activities/reviews/[review_id]/action-sheet/ActivityReviewActionSheet"
        ),
    ),
    // 후기 작성 후 랜딩페이지
    "review.landing": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/reviews/[review_id]/landing/ActivityReviewLanding"
          ),
      ),
      layout: ActivityReviewLandingLayout,
    }),
    // 후기 성과 페이지
    "review.performance_landing": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/reviews/[review_id]/performance-landing/ActivityReviewPerformanceLanding"
          ),
      ),
      layout: ActivityReviewPerformanceLandingLayout,
    }),
    // 로컬프로필 제안 참여 완료 후 랜딩페이지
    "local_profile_suggestion.landing": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/local-profile-suggestions/[local_profile_suggestion_id]/landing/ActivityLocalProfileSuggestionLanding"
          ),
      ),
      layout: ActivityLocalProfileSuggestionLandingLayout,
    }),
    // 로컬프로필 제안 검수 통과 페이지
    "local_profile_suggestion.inspection_completed": lazy(
      () =>
        import(
          "../activities/local-profile-suggestions/[local_profile_suggestion_id]/inspection-completed/ActivityLocalProfileSuggestionInspectionCompleted"
        ),
    ),
    // 로컬프로필에 UGC 생산하기 홈 (기여모드)
    "local_profile.contribute": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/local-profiles/contribute/ActivityLocalProfileContribute"
          ),
      ),
      layout: ActivityLocalProfileContributeLayout,
    }),
    // UGC를 생산할 로컬프로필 추천 목록
    "local_profile.contribute.recommendations": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/local-profiles/contribute/recommendations/ActivityLocalProfileContributeRecommendations"
          ),
      ),
      layout: ActivityLocalProfileContributeRecommendationsLayout,
    }),
    // 로컬프로필 기여 목록
    "local_profile.contributions": lazy(
      () =>
        import(
          "../activities/local-profiles/[local_profile_id]/contributions/ActivityLocalProfileContributionList"
        ),
    ),
    // 로컬프로필 수정 제안
    "local_profile.update": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/local-profiles/[local_profile_id]/update/ActivityLocalProfileUpdate"
          ),
      ),
      layout: ActivityLocalProfileUpdateLayout,
    }),
    // 로컬프로필 배경 사진 제안
    "local_profile.update.background_image": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/local-profiles/[local_profile_id]/update/background-image/ActivityLocalProfileUpdateBackgroundImage"
          ),
      ),
      layout: ActivityLocalProfileUpdateBackgroundImageLayout,
    }),

    // 소셜 캐피탈 리다이렉트 대시보드
    "social_capital.redirect.dashboard": withActivityRenderer({
      layout: ActivitySocialCapitalRedirectOnboardingOrDashboardLayout,
      component: lazy(
        () =>
          import(
            "../activities/social-capital/redirect/onboarding-or-dashboard/ActivitySocialCapitalRedirectOnboardingOrDashboard"
          ),
      ),
    }),
    // 소셜 캐피탈 온보딩
    "social_capital.onboarding": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/seasons/[social_capital_season_key]/onboarding/ActivitySocialCapitalOnboarding"
          ),
      ),
      layout: ActivitySocialCapitalOnboardingLayout,
    }),
    // 소셜 캐피탈 지역 대시보드
    "social_capital.dashboard": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/seasons/[social_capital_season_key]/regions/[region_id]/dashboard/ActivitySocialCapitalRegionDashboard"
          ),
      ),
      layout: ActivitySocialCapitalRegionDashboardLayout,
    }),
    // 소셜 캐피탈 랭킹
    "social_capital.ranking": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/seasons/[social_capital_season_key]/ranking/ActivitySocialCapitalRanking"
          ),
      ),
      layout: ActivitySocialCapitalRankingLayout,
    }),
    // 소셜 캐피탈 후기 작성 완료
    "social_capital.review.landing": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/reviews/[review_id]/landing/ActivitySocialCapitalReviewLanding"
          ),
      ),
      layout: ActivitySocialCapitalReviewLandingLayout,
    }),
    // 소셜 캐피탈 기여모드 후기 작성 완료
    "social_capital.review.contribute_landing": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/reviews/[review_id]/contribute-landing/ActivitySocialCapitalContributeReviewLanding"
          ),
      ),
      layout: ActivitySocialCapitalContributeReviewLandingLayout,
    }),
    // 소셜 캐피탈 기여모드 제안 완료
    "social_capital.suggestion.contribute_landing": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/suggestions/[suggestion_id]/contribute-landing/ActivitySocialCapitalContributeSuggestionLanding"
          ),
      ),
      layout: ActivitySocialCapitalContributeSuggestionLandingLayout,
    }),
    // 소셜 캐피탈 포인트 시스템
    "social_capital.point_system": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/point-system/ActivitySocialCapitalPointSystem"
          ),
      ),
      layout: ActivitySocialCapitalPointSystemLayout,
    }),
    // 소셜 캐피탈 공유하기 바텀시트
    "social_capital.bottom_sheet.share": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/bottom-sheet/share/ActivitySocialCapitalShareBottomSheet"
          ),
      ),
      layout: ActivitySocialCapitalShareBottomSheetLayout,
    }),
    // 소셜 캐피탈 법정동 선택
    "social_capital.regions.search_legal_region": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/regions/search-legal-region/ActivitySocialCapitalSearchLegalRegion"
          ),
      ),
      layout: ActivitySocialCapitalSearchLegalRegionLayout,
    }),
    // 소셜 캐피탈 시즌 집계
    "social_capital.season_counting": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/seasons/[social_capital_season_key]/counting/ActivitySocialCapitalSeasonCounting"
          ),
      ),
      layout: ActivitySocialCapitalSeasonCountingLayout,
    }),
    // 소셜 캐피탈 시즌 집계 종료
    "social_capital.season_counting_finished": withActivityRenderer({
      component: lazy(
        () =>
          import(
            "../activities/social-capital/seasons/[social_capital_season_key]/counting-finished/ActivitySocialCapitalSeasonCountingFinished"
          ),
      ),
      layout: ActivitySocialCapitalSeasonCountingFinishedLayout,
    }),
    // 존재하지 않는 페이지 접근
    "error.not_found": ActivityNotFound,
  },
  plugins: [
    historySyncPlugin({
      urlPatternOptions: {
        segmentNameCharset: "a-zA-Z0-9_-",
      },
      routes: {
        profile: "/profiles/:profile_id",
        "profile.onboarding_bottom_sheet":
          "/profiles/:profile_id/onboarding-bottom-sheet",
        "profile.local_profile_reviews":
          "/profiles/:profile_id/local-profile-reviews",
        "profile.local_profile_suggestions":
          "/profiles/:profile_id/local-profile-suggestions",
        "profile.contributed_local_profiles":
          "/profiles/:profile_id/contributed-local-profiles",
        "review.create": "/reviews/new",
        review: "/reviews/:review_id",
        "review.update": "/reviews/:review_id/edit",
        "review.landing": "/reviews/:review_id/landing",
        "review.action_sheet": "/reviews/:review_id/action-sheet",
        "review.performance_landing": "/reviews/:review_id/performance-landing",
        "local_profile_suggestion.landing":
          "/local-profile-suggestions/:local_profile_suggestion_id/landing",
        "local_profile_suggestion.inspection_completed":
          "/local-profile-suggestions/:local_profile_suggestion_id/inspection-completed",
        "local_profile.contribute": "/local-profiles/contribute",
        "local_profile.contribute.recommendations":
          "/local-profiles/contribute/recommendations",
        "local_profile.contributions":
          "/local-profiles/:local_profile_id/contributions",
        "local_profile.update": "/local-profiles/:local_profile_id/update",
        "local_profile.update.background_image":
          "/local-profiles/:local_profile_id/update/background-image",
        /*
         * 소셜 캐피탈
         * */
        "social_capital.redirect.dashboard":
          "/social-capital/redirect/dashboard",
        "social_capital.onboarding":
          "/social-capital/seasons/:social_capital_season_key/onboarding",
        "social_capital.onboarding.region_selection_bottom_sheet":
          "/social-capital/seasons/:social_capital_season_key/onboarding/regions-selection-bottom-sheet",
        "social_capital.regions.legal_region_selection":
          "/social-capital/regions/:region_id/legal-region-selection",
        "social_capital.dashboard":
          "/social-capital/seasons/:social_capital_season_key/regions/:region_id/dashboard",
        "social_capital.ranking":
          "/social-capital/seasons/:social_capital_season_key/ranking",
        "social_capital.season_counting":
          "/social-capital/seasons/:social_capital_season_key/counting",
        "social_capital.season_counting_finished":
          "/social-capital/seasons/:social_capital_season_key/counting-finished",
        "social_capital.review.landing":
          "/social-capital/reviews/:review_id/landing",
        "social_capital.review.contribute_landing":
          "/social-capital/reviews/:review_id/contribute-landing",
        "social_capital.suggestion.contribute_landing":
          "/social-capital/suggestions/:local_profile_suggestion_id/contribute-landing",
        "social_capital.point_system": "/social-capital/point-system",
        "social_capital.bottom_sheet.share":
          "/social-capital/bottom-sheet/share",
        "social_capital.regions.search_legal_region":
          "/social-capital/search/legal-region",
        "error.not_found": "/404",
      },
      fallbackActivity: () => "error.not_found",
    }),
    basicRendererPlugin(),
    basicUIPlugin({
      theme: THEME,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor:
          THEME === "cupertino"
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        closeButton: {
          renderIcon: () => <IconBack />,
          onClick() {
            bridge.closeRouter({});
          },
        },
      },
    }),
    stackDepthChangePlugin({
      onInit: () => {
        bridge.styleCurrentRouter({
          router: {
            navbar: false,
            scrollable: true,
            backSwipable: true,
          },
        });
      },
      onDepthChanged: ({ depth }) => {
        bridge.styleCurrentRouter({
          router: {
            navbar: false,
            scrollable: true,
            backSwipable: depth === 0,
          },
        });
      },
    }),
    karrotAnalyticsPlugin({
      bridge: bridge,
      serviceName: LOCAL_BUSINESS_UGC_SERVICE_NAME,
    }),
    localBusinessAnalyticsStackflowPlugin({
      debug: !IS_PROD,
      logEvent: ({ name, params }) =>
        logEvent({ bridge: { type: "KARROT_BRIDGE", bridge } })({
          name,
          params: {
            ...params,
            where: `${LOCAL_BUSINESS_UGC_SERVICE_NAME}.${params.where}`,
          },
        }),
      commonPayloads: {
        referrer: getQueryParam("referrer"),
      },
    }),
    !IS_PROD ? devtoolsPlugin() : [],
    /* CBT기간(8/29~9/12) 동안 사내구성원 에게만 소셜캐피탈 기능을 노출합니다. */
    disableSocialCapitalPlugin(),
    /* 소셜 캐피탈, 점검 여부 feature flag 를 통해 기존 페이지를 점검중 페이지로 노출해요.   */
    maintenanceModeSocialCapitalPlugin(),
  ],
});

export { actions, Stack };
export type TypeUseFlow = typeof useFlow;
export type TypeUseStepFlow = typeof useStepFlow;
export type Activities = typeof activities;
export { useStepFlow };

// const a: Activities = {
//   profile: ({params}) => params.,
//   "profile.local_profile_reviews": ({params}) =>params.profile_user_id
// }
